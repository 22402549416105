<template>
  <div style="padding: 20px">
    <label>Push Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="push" />
        <div class="stats-bar-item-title">Sent:</div>
        <div class="stats-bar-item-number">
          {{ pushSentTotal | number }}
          ({{ pushSentTotal | percentageOf(pushArrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mobile" />
        <div class="stats-bar-item-title">Delivered:</div>
        <div class="stats-bar-item-number">
          {{ pushDeliveredTotal | number }}
          ({{ pushDeliveredTotal | percentageOf(pushSentTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mouse" />
        <div class="stats-bar-item-title">Clicks:</div>
        <div class="stats-bar-item-number">
          {{ pushClickedTotal | number }}
          ({{ pushClickedTotal | percentageOf(pushDeliveredTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <date-line-chart
      v-if="viewMode === 'numbers'"
      key="numbersChart"
      :chart-data="numbersChartData"
      title="Sent, Clicks"
      title-class="action-color-email"
      :yLabel="$t('actionReportingNumberOfProfiles')"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

export default {
  props: [
    'dateRange',

    'pushArrivedTotal',
    'pushSentTotal',
    'pushDeliveredTotal',
    'pushClickedTotal',

    'pushSentStats',
    'pushClickedStats'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  data() {
    return {
      viewMode: 'numbers'
    }
  },

  computed: {
    numbersChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Sent',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.pushSentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.pushSentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.pushClickedStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.pushClickedStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    },

    percentChartData() {
      return {}
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.clickedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.clickedPercentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
